import React from "react";
import styles from "./enfants.module.scss";
import fleurs from "./fleurs.jpg";
import fleurs2 from "./fleurs2.png";
const Enfants = () => {
  return (
    <>
      <div className={styles.presentationContainer}>
        <div className={styles.left}>
          <div className={styles.leftContent}>
            <h2>Comment se déroule une séance?</h2>
            <p className={styles.espacement}>
              Les séances durent en moyenne 1 heure (35 minutes pour les
              enfants) – que cela soit au cabinet ou en visio. Elles peuvent se
              pratiquer assis ou debout. Chaque séance est individualisée: nous
              partons de vous et de votre besoin. <br /> <br /> La première
              séance est un temps d'échange autour de vous et de vos attentes
              afin de définir vos besoins et de dessiner un plan
              d'accompagnement adapté. Elle est aussi l'occasion de vous faire
              expérimenter ce que peut être un état d'hypnose au cours d'une
              séance et d’effectuer un premier travail.
            </p>{" "}
            <br />
            <p className={styles.espacement}>
              Les séances suivantes se déroulent d’une manière générale en 3
              temps:
            </p>
            <ol className={styles.espacement}>
              <li>
                Un premier temps d’échange pour refaire ensemble un point et
                définir un objectif de séance.
              </li>
              <li>
                Un second temps d’induction où je vous guide dans un état de
                conscience modifié.
              </li>
              <li>Un troisième temps qui est un temps de travail.</li>
            </ol>
            <br />
            <p className={styles.espacement}>
              Je décris souvent une séance comme un espace de cocréation: je
              suis un fil conducteur qui vous aide à vous connecter à vos
              propres ressources vous permettant, vous, de mieux vous connaître
              et de mieux comprendre quels sont vos modes de fonctionnement.
            </p>
          </div>
        </div>
        {/* <div className={styles.fleursContainer}> */}
        <img src={fleurs} id={styles.rightImg} alt="fleur" />
        {/* </div> */}
      </div>
    </>
  );
};

export default Enfants;
