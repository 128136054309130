import React from "react";
import styles from "./testimonies.module.scss";
import TestimonieCard from "../../components/Testimonie";
import { NavLink } from "react-router-dom";
import { cards } from "./cardData";
const Testimonies = () => {
  return (
    <>
      <div className={styles.testimoniesContainer}>
        <div className={styles.testimoniesTitle}>
          <h2>Témoignages</h2>

          <NavLink to="/Partage">
            <button>Partagez votre témoignage</button>
          </NavLink>
        </div>
        <div className={styles.temoignagesGoogle}>
          Retrouvez les autres témoignages Google (en cliquant
          <a
            href="https://www.google.com/search?sca_esv=45c41d3e543101a5&sxsrf=AHTn8zpDDR3YVky_TjIIIXUdWOIByqd2Vw:1742324814709&q=elisabeth+fournier+lyon+hypnose&si=APYL9bs7Hg2KMLB-4tSoTdxuOx8BdRvHbByC_AuVpNyh0x2Kze3s4S0rfFg7kIJ9jsRJacAyqc043IO5NdlcFUHncmhBq5V9lFwAtfc6PVq7j0P3KOLaSNc%3D&uds=ABqPDvzf4SA5zaiOI49mS6FD4AJSSdSdhUg5afwETxM7PS9z-cA22JuG2_YVfrg-Kz8fnIeguXhoyPS1HMmT9MWpc3h7j7k_Zgb2leK-FtM79TX2M24LYbUJuNjQuEpz-vi2TmK8Co6C&sa=X&ved=2ahUKEwiK46fdqZSMAxXnVqQEHXuJDJgQ3PALegQIGRAE&biw=1317&bih=634&dpr=2"
            target="blank"
            className={styles.google}
          >
            <u>ici</u>
          </a>
          )
        </div>

        <div className={styles.testimonies}>
          <ul>
            {cards
              .sort((a, b) => b.id - a.id)
              .map((card) => (
                <TestimonieCard key={card.id} card={card} />
              ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Testimonies;
