import React from "react";
import styles from "./autohypnose.module.scss";
import Femme from "./femme.jpg";
import Button from "../../components/Button";
const Autohypnose = () => {
  return (
    <>
      <div className={styles.autohypnoseContainer}>
        <div className={styles.left}>
          <div className={styles.leftContent}>
            <h2>Ateliers collectifs d'autohypnose</h2>
            <p className={styles.espacement}>
              Apaiser le flux de ses pensées, prendre de la distance, faciliter
              son endormissement, réguler son stress... l'autohypnose est un
              puissant outil de régulation émotionnelle, de lâcher prise et de
              relaxation qui peut s'utiliser dans nombre de situations de nos
              vies quotidiennes.{" "}
            </p>
            <p className={styles.espacement}>
              Je vous propose de venir le découvrir - et apprendre à l’utiliser
              - au cours{" "}
              <u>
                d'un atelier/apéro (exclusivement féminin) de 2 heures - les
                samedis de 18h à 20h.
              </u>
            </p>
            <p className={styles.espacement}>
              Le principe est le suivant: 1h30 de découverte et de pratique de
              l'autohypnose (en petit groupe de femmes - 6 maximum) suivie d'un
              apéritif pour faire connaissance et échanger différemment pour
              celles qui en ont envie. Parce que c'est aussi cela qui m'anime:
              rencontrer, transmettre et partager!
            </p>
            <p className={styles.espacement}>
              <u>
                Les prochains ateliers se tiendront les samedis 5 avril, 17 mai
                et 14 juin.
              </u>
            </p>
            <br />
            <p className={styles.noSpace}>
              Thème 1 : Apaiser le flux de ses pensées
            </p>
            <ul>
              <li>Samedi 5 avril (1 place restante)</li>
              <li>Samedi 17 mai</li>
            </ul>
            <p className={styles.noSpace}>
              Thème 2: Prendre de la distance et réguler son stress
            </p>
            <ul className={styles.liste}>
              <li>Samedi 14 juin </li>
            </ul>
            <br />
            <p className={styles.espacement}>
              Tarif: 30€/personne (règlement par chèque, virement ou espèce)
            </p>

            <p className={styles.espacement}>
              Lieu: 49 Cours Gambetta - 69003 Lyon (Metro B et D Saxe Gambetta)
            </p>
            <Button width="L">
              <a
                href="https://elisabethfournier.simplybook.net/v2/"
                target="_blank"
                rel="noreferrer"
              >
                Réserver sa place en atelier
              </a>
            </Button>
          </div>
        </div>
        <img src={Femme} id={styles.rightImg} alt="fleur" />
      </div>
    </>
  );
};

export default Autohypnose;
